<template>
  <div class="g_page_box">
    <div class="g_main_content main_content">
      <g-loading :loading="loading" />
      <img src="../../../assets/img/pay_success.png" class="icon" width="153">
      <div class="title">提交成功</div>
      <div class="surplus_time">正在等待医生进行麻醉评估，医生评估结果会通过公众号推送给您。请您留意微信推送</div>
    </div>
    <div class="bottom_box">
      <router-link :to="{
        path: '/',
      }">
        <div class="btn" >完成</div>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      loading: false,
    }
  },
  computed: {
  },
  components: {
  },
  created () {
  },
  methods: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.main_content {
  text-align: center;
}
.icon {
  margin-top: 65px;
}
.title {
  font-size: 19px;
  font-weight: 600;
  line-height: 27px;
  color: #242938;
  margin-top: 28px;
}
.surplus_time {
  max-width: 74%;
  font-size: 14px;
  line-height: 20px;
  color: #242938;
  margin: 8px auto;
  & label {
    color: #0088FF;
  }
}
.bottom_box {
  padding: 24px 13px;
  & .btn {
    width: 100%;
    background: #0088FF;
    border-radius: 9px;
    line-height: 40px;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    color: #FFFFFF;

    &.s2 {
      margin-bottom: 10px;
      background: #fff;
      color: #0088ff;
      border: solid 1px #0088ff;
    }
  }
}
</style>
